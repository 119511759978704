var searchKeys = [{
  key: "classify",
  label: "分类",
  placeholder: "请输入",
  required: false,
  rules: [],
  select: true
}, {
  key: "title",
  label: "资讯标题",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: "source",
  label: "资讯来源方式",
  placeholder: "请选择",
  required: false,
  rules: [],
  select: true
}, {
  key: "url",
  label: "资讯来源链接",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: 'dates',
  label: '来源时间',
  format: "YYYY-MM-DD HH:mm",
  placeholder: ["开始时间", "结束时间"],
  dates: true,
  rules: [{
    type: 'array',
    required: false,
    message: '请选择时间段!'
  }]
}, {
  key: 'time',
  label: '发布时间',
  format: "YYYY-MM-DD HH:mm",
  placeholder: ["开始时间", "结束时间"],
  dates: true,
  rules: [{
    type: 'array',
    required: false,
    message: '请选择时间段!'
  }]
},
/*{
    key: "showinfrontpage",
    label: "推送首页",
    placeholder: "请选择",
    required: false,
    rules: [],
    select: true
},*/
{
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };