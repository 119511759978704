var columns = [{
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
  width: '7%',
  scopedSlots: {
    customRender: 'id'
  }
}, {
  title: '资讯来源方式',
  dataIndex: 'sourceway',
  key: 'sourceway',
  width: '10%',
  scopedSlots: {
    customRender: 'sourceway'
  }
}, {
  title: '资讯来源媒体',
  dataIndex: 'source',
  key: 'source',
  width: '10%',
  scopedSlots: {
    customRender: 'source'
  }
}, {
  title: '资讯来源链接',
  dataIndex: 'url',
  key: 'url',
  width: '8%',
  scopedSlots: {
    customRender: 'url'
  }
}, {
  title: '资讯标题',
  dataIndex: 'titlename',
  key: 'titlename',
  width: '15%',
  scopedSlots: {
    customRender: 'titlename'
  }
}, {
  title: '分类',
  dataIndex: 'classname',
  key: 'classname',
  width: '10%',
  scopedSlots: {
    customRender: 'classname'
  }
}, {
  title: '状态',
  dataIndex: 'status',
  key: 'status',
  width: '10%',
  scopedSlots: {
    customRender: 'status'
  }
},
/*{
    title: '是否推送首页',
    dataIndex: 'showinfrontpage',
    key: 'showinfrontpage',
    width: '8%',
    scopedSlots: {
        customRender: 'showinfrontpage'
    }
},*/
{
  title: '来源时间(UTC+0)',
  dataIndex: 'createtime',
  key: 'createtime',
  width: '10%',
  scopedSlots: {
    customRender: 'createtime'
  }
}, {
  title: '发布时间(UTC+0)',
  dataIndex: 'publishtime',
  key: 'publishtime',
  width: '10%',
  scopedSlots: {
    customRender: 'publishtime'
  }
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  fixed: "right",
  width: 200,
  scopedSlots: {
    customRender: 'action'
  }
}];
export { columns };